.skill-logo {
    font-size: 24px;
}

.about-me-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 120px 0;
}

.about-me-content {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
}

.skills {
    display: flex;
    gap: 12px;
}

.skills-group {
    width: 12rem;
    border: 1px solid var(--color-fg);
    height: max-content;
}

.skills-group-title {
    padding: 0.65rem;
    border-bottom: 1px solid var(--color-fg);
}

.skills-group-text {
    padding: 0.65rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.skill-item {
    display: flex;
    align-items: center;
    gap: 0.65rem;
    transition: 0.2s ease-out;
}

.skill-item:hover {
    transform: translateX(0.5rem);
    transition: 0.2s ease-out;
}

@media (max-width: 900px) {
    .about-me-content {
        flex-direction: column;
    }

    .skills-group {
        width: 100%;
    }
}
