.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    font-size: 1.728rem;
}

.navbar-left {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--color-primary);
}

.navbar-left svg {
    height: 30px;
    width: 30px;
    animation: rotation 5s infinite linear;
}

.navbar-left span {
    font-weight: bold;
}

.navbar-right {
    display: flex;
    align-items: center;
    gap: 40px;
    font-size: 1.44rem;
}

.nav-li {
    display: inline-block;
    position: relative;
}

.nav-li:hover a {
    color: var(--color-primary);
}

.nav-li::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: var(--color-primary);
    transition: width 0.3s ease-in-out;
}

.nav-li:hover::after {
    width: 100%;
}

.navbar-right select {
    height: 35px;
    border: none;
    background-color: var(--color-bg);
    color: var(--color-fg);
    font-family: Iosevka;
    font-size: 1.728rem;
}

.navbar-right select:hover {
    cursor: pointer;
}

#sidebar-active {
    display: none;
}

.open-menu-btn {
    all: unset;
    display: none;
    margin: 0;
    height: 40px;
    font-size: 2.5rem;
    cursor: pointer;
}

.open-menu-btn:hover {
    color: var(--color-primary);
}

#overlay {
    display: none;
}

.mobile-menu {
    position: fixed;
    padding: 4rem 3rem;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 8em;
    z-index: 999;
    background: var(--color-bg);
    border-left: 1px solid var(--color-fg);
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    font-size: 1.44rem;
    transition: 0.3s ease-out;
}

.mobile-menu label i {
    font-size: 2.5rem;
    margin: -1rem 0 2.5rem -1rem;
    cursor: pointer;
}

.mobile-menu label i:hover {
    color: var(--color-primary);
}

.mobile-menu-items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.mobile-menu-items li {
    width: fit-content;
}

@media (max-width: 900px) {
    .navbar-right {
        display: none;
    }

    .open-menu-btn {
        display: block;
    }

    #sidebar-active:checked~.mobile-menu {
        right: 0;
    }

    #sidebar-active:checked~#overlay {
        display: block;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
