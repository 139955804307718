footer {
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

footer span {
    color: var(--color-primary);
}
