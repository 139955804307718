a {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0;
}

ul,
ol,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.App {
    color: var(--color-fg);
}

.container {
    margin: 0 auto;
    width: clamp(320px, 75vw, 1000px);
}

.section-title {
    font-size: 2.488rem;
    margin: 0;
}

.section-title span {
    color: var(--color-primary);
}

.hidden {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.show {
    opacity: 1;
    transform: translateY(0);
}
