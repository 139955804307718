.my-links-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 120px 0;
}

.my-links-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.link {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--color-fg);
    padding: 10px 20px;
    transition: 0.2s ease-in;

}

.link:hover {
    transform: translateY(-0.5rem);
    transition: 0.2s ease-out;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
}

.link-icon {
    font-size: 30px;
}
