.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 0;
    margin: 5rem 0 0 0;
}

.hero-title {
    font-size: 2.986rem;
}

#my-name {
    font-weight: bold;
}

#fullstack {
    font-weight: bold;
    color: var(--color-primary);
}

.my-profile {
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-fg);
    padding: 40px 20px;
    gap: 10px;
    text-align: center;
}

.my-profile img {
    width: 160px;
    border-radius: 50%;
}

.my-profile span {
    font-size: 0.833rem;
}

@media (max-width: 900px) {
    .hero {
        flex-direction: column;
        gap: 2rem;
        padding: 0;
        margin: 2.5rem 0 0 0;
    }

    .hero-title {
        text-align: center;
    }

    .my-profile {
        width: fit-content;
        padding: 2.5rem;
    }
}
