@import 'boxicons/css/boxicons.min.css';

@font-face {
    font-Family: Iosevka;
    src: url('/public/fonts/IosevkaTermNerdFont-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-Family: Iosevka;
    src: url('/public/fonts/IosevkaTermNerdFont-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


:root {
    --color-bg: #1F1F28;
    --color-bg-2: #54546D;
    --color-fg: #DDD8BB;
    --color-primary: #957FB8;
}

html::-webkit-scrollbar {
    width: 10px;
}

html::-webkit-scrollbar-track {
    background: var(--color-bg);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

html::-webkit-scrollbar-thumb {
    background: var(--color-fg);
}

body {
    background-color: var(--color-bg);
    margin: 0;
    font-family: Iosevka, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
