.my-works-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 120px 0;
}

.projects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    justify-content: space-between;
    grid-gap: 20px 20px;
}
