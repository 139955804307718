.project {
    border: 1px solid var(--color-fg);
    transition: 0.2s ease-out;
    display: flex;
    flex-direction: column;
}

.project:hover {
    transform: translateY(-0.2rem);
    transition: 0.2s ease-out;
}

.project-img {
    background-color: var(--color-fg);
    width: auto;
    height: 12.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-img img {
    height: 100%;
}

.project-wrapper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    gap: 1rem;
    height: auto;
}

.project-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.project-text h4 {
    font-size: 1.728rem;
    margin: 0;
}

.tags {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.tag {
    padding: 4px 8px;
    background: var(--color-bg-2);
}

.project-btn {
    display: flex;
    justify-content: center;
    border: 1px solid var(--color-fg);
    padding: 0.65rem 0;
    font-size: 0.833rem;
    color: var(--color-fg);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.project-btn:hover {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
}
